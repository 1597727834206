import { CreateKnowledgeBaseContentSchema, EditKnowledgeBaseContentSchema } from 'validations/KnowledgeBaseContentSchema'
import { KnowledgeBaseVisibilityType } from 'types/KnowledgeBaseVisibilityType'
import { KnowledgeBaseContentFormType, KnowledgeBaseContentFormFields } from './types'

const commonDefaultValues = {
  name: '',
  nameTranslations: [],
  description: '',
  descriptionTranslations: [],
  destination: null,
  knowledgeBaseFilesIds: [],
  visibility: KnowledgeBaseVisibilityType.Company,
  image: null,
}

const presentationTestDefaultValues = {
  difficulty: 3,
  timeline: undefined,
  scene: undefined,
}

const getDefaultValues = (
  type: KnowledgeBaseContentFormType,
  initValues?: KnowledgeBaseContentFormFields
): KnowledgeBaseContentFormFields => {
  switch (type) {
    case KnowledgeBaseContentFormType.QUIZ:
      return {
        ...commonDefaultValues,
        ...initValues,
        duration: null,
        type: KnowledgeBaseContentFormType.QUIZ,
        subForm: {
          canGoBack: false,
          quizDuration: false,
          showAnswers: false,
          randomQuestionOrder: false,
          markWeight: 1,
          quantityOfRandomQuestions: null,
          difficulty: 3
        }
      }
    case KnowledgeBaseContentFormType.PRESENTATION:
      return {
        ...commonDefaultValues,
        ...initValues,
        duration: initValues?.duration || 60,
        type: KnowledgeBaseContentFormType.PRESENTATION,
        subForm: presentationTestDefaultValues
      }
    case KnowledgeBaseContentFormType.TEST:
      return {
        ...commonDefaultValues,
        ...initValues,
        duration: initValues?.duration || 60,
        type: KnowledgeBaseContentFormType.TEST,
        subForm: presentationTestDefaultValues
      }
    case KnowledgeBaseContentFormType.SANDBOX_TEST:
      return {
        ...commonDefaultValues,
        ...initValues,
        duration: initValues?.duration || 60,
        type: KnowledgeBaseContentFormType.SANDBOX_TEST,
        subForm: presentationTestDefaultValues
      }
    default:
      return {
        ...commonDefaultValues,
        ...initValues,
        duration: initValues?.duration || 60,
        type: KnowledgeBaseContentFormType.TEST,
        subForm: presentationTestDefaultValues
      }
  }
}

const getValidationSchema = (isEditing: boolean) => (
  isEditing
    ? EditKnowledgeBaseContentSchema
    : CreateKnowledgeBaseContentSchema
)

export {
  getDefaultValues,
  getValidationSchema,
}
