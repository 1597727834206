import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik, FormikErrors, FormikTouched } from 'formik'
import { Typography } from '@material-ui/core'

import { GroupType } from 'types/GroupType'
import { useIsMobile } from 'hooks/useIsMobile'
import { Translation } from 'types/Translation'
import {
  KnowledgeBaseContentFormType,
  KnowledgeBaseContentFormFields,
  QuizSubForm,
  PresentationTestSubForm,
} from './types'
import { getDefaultValues, getValidationSchema } from './utils'
import { QuizFormFields } from './KnowledgeBaseContentFormFields/QuizFormFields'
import { PresentationTestFormFields } from './KnowledgeBaseContentFormFields/PresentationTestFormFields'
import * as Styled from './KnowledgeBaseContentForm.styles'

interface KnowledgeBaseContentFormProps {
  initialValues?: KnowledgeBaseContentFormFields
  initialDestination?: number
  destinationPlaceholder?: string
  onSubmit?: (values: KnowledgeBaseContentFormFields, languageCode: string) => void
  isLoading?: boolean
  isError?: boolean
}

const KnowledgeBaseContentForm: React.FC<KnowledgeBaseContentFormProps> = ({
  initialValues,
  initialDestination,
  destinationPlaceholder,
  isError,
  isLoading,
  onSubmit = () => {},
}) => {
  const { t, i18n: { language: userLanguageBrowserCode } } = useTranslation()
  const isMobile = useIsMobile()

  const [translationLanguages, setTranslationLanguages] = useState<string[]>(
    initialValues?.nameTranslations.map((value) => value.languageCode) || []
  )

  const isEditing = !!initialValues

  const submit = (values: KnowledgeBaseContentFormFields) => {
    onSubmit(values, userLanguageBrowserCode)
  }

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    validateForm,
    setValues,
    setTouched,
    setErrors,
  } = useFormik<KnowledgeBaseContentFormFields>({
    initialValues: {
      ...getDefaultValues(KnowledgeBaseContentFormType.PRESENTATION),
      destination: initialDestination || null,
      ...initialValues,
    },
    validationSchema: getValidationSchema(isEditing),
    onSubmit: submit,
    enableReinitialize: true,
  })

  const getValueForLanguageCode = (translations: Translation[], languageCode: string) => (
    translations.find((translation) => translation.languageCode === languageCode)?.value
  )

  const handleChangeTranslationLanguages = (event: ChangeEvent<{ value: unknown }>) => {
    const newTranslationLanguages = event.target.value as string[]
    setTranslationLanguages(newTranslationLanguages)
    const currentNameTranslations = values.nameTranslations
    const currentDescriptionTranslations = values.descriptionTranslations
    setFieldValue('nameTranslations', newTranslationLanguages.map((lng) => ({
      languageCode: lng,
      value: getValueForLanguageCode(currentNameTranslations, lng) || ''
    })))
    setFieldValue('descriptionTranslations', newTranslationLanguages.map((lng) => ({
      languageCode: lng,
      value: getValueForLanguageCode(currentDescriptionTranslations, lng) || ''
    })))
  }

  const handleImageChange = (file: File) => {
    setFieldValue('image', file || null)
    setFieldTouched('image', true, false)
    validateForm({ ...values, image: file })
  }

  const languageCodeSorter = (a: Translation, b: Translation) => (
    a.languageCode > b.languageCode
      ? 1
      : (a.languageCode === b.languageCode ? 0 : -1)
  )

  return (
    <Styled.Form onSubmit={handleSubmit}>
      <Styled.HeaderContainer>
        <Typography variant='h2'>
          {t('knowledgeBase.content')}
        </Typography>
        <Styled.SaveButton
          $isMobile={isMobile}
          type='submit'
          isLoading={isLoading}
          disabled={isError}
        >
          {t('common.save')}
        </Styled.SaveButton>
      </Styled.HeaderContainer>
      <Styled.InfoSection
        label={t('knowledgeBase.destination')}
        editValueComponent={
          <Styled.DestinationValue>
            {destinationPlaceholder}
          </Styled.DestinationValue>
        }
      />
      <Styled.InfoSection
        label={t('common.type')}
        editValueComponent={
          <Styled.GroupSelect
            id='type'
            name='type'
            label=''
            disabled={isEditing}
            value={values.type}
            onChange={(event) => {
              const newType = event.target.value as KnowledgeBaseContentFormType
              setValues(getDefaultValues(newType, values))
              setTouched({})
              setErrors({})
            }}
            onBlur={handleBlur}
            touched={!!touched.type}
            errorMessage={t(errors.type as unknown as string || '')}
            without={[GroupType.ALL, GroupType.GROUP]}
          />
        }
      />
      <Styled.InfoSection
        label={t('common.visibility')}
        editValueComponent={
          <Styled.KnowledgeBaseVisibilitySelect
            id='visibility'
            name='visibility'
            label=''
            value={values.visibility}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={!!touched.visibility}
            errorMessage={t(errors.visibility as unknown as string || '')}
          />
        }
      />

      <Styled.InfoSection
        label={`${t('common.name')} - ${userLanguageBrowserCode}`}
        editValueComponent={
          <Styled.TextField
            id='name'
            name='name'
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.name}
            errorMessage={t(errors.name || '')}
          />
          }
      />
      <Styled.InfoSection
        label={`${t('common.description')} - ${userLanguageBrowserCode}`}
        editValueComponent={
          <Styled.TextField
            id='description'
            name='description'
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.description}
            errorMessage={t(errors.description || '')}
            multiline
            minRows={4}
          />
        }
      />
      <Styled.InfoSection
        label={t('knowledgeBase.translations')}
        editValueComponent={
          <Styled.TranslationLanguagesSelect
            multiple
            value={translationLanguages}
            onChange={handleChangeTranslationLanguages}
            without={[userLanguageBrowserCode]}
          />
        }
      />
      {values.nameTranslations.sort(languageCodeSorter).map((value, index) => (
        <Styled.InfoSection
          key={value.languageCode}
          label={`${t('common.name')} - ${value.languageCode}`}
          editValueComponent={
            <Styled.TextField
              name={`nameTranslations[${index}.value]`}
              value={values.nameTranslations[index].value}
              onChange={handleChange}
            />
          }
        />
      ))}
      {values.descriptionTranslations.sort(languageCodeSorter).map((value, index) => (
        <Styled.InfoSection
          key={value.languageCode}
          label={`${t('common.description')} - ${value.languageCode}`}
          editValueComponent={
            <Styled.TextField
              name={`descriptionTranslations[${index}.value]`}
              value={values.descriptionTranslations[index].value}
              onChange={handleChange}
            />
          }
        />
      ))}

      <Styled.InfoSection
        label={t('knowledgeBase.photo')}
        editValueComponent={
          <Styled.UploadImageField
            disabled={!isEditing}
            error={!!errors.image}
            errorMessage={t(errors.image || '')}
            touched={touched.image}
            onFileChange={handleImageChange}
            file={values.image}
          />
        }
      />
      {values.type !== 'QUIZ' && (
        <PresentationTestFormFields
          onBlur={handleBlur}
          onChange={handleChange}
          setFieldValue={setFieldValue}
          contentValues={values.subForm}
          touched={touched}
          errors={errors}
          values={{
            duration: values.duration,
          }}
          contentTouched={touched.subForm as FormikTouched<PresentationTestSubForm>}
          contentErrors={errors.subForm as FormikErrors<PresentationTestSubForm>}
        />
      )}
      {values.type === 'QUIZ' && (
        <QuizFormFields
          isEditing={isEditing}
          onBlur={handleBlur}
          onChange={handleChange}
          setFieldValue={setFieldValue}
          quizValues={values.subForm}
          touched={touched}
          errors={errors}
          values={{
            duration: values.duration,
          }}
          quizTouched={touched.subForm as FormikTouched<QuizSubForm>}
          quizErrors={errors.subForm as FormikErrors<QuizSubForm>}
        />
      )}
    </Styled.Form>
  )
}

export type { KnowledgeBaseContentFormFields }
export { KnowledgeBaseContentForm }
