import { Addressable } from 'api/queries/companyAddressables/types'
import { KnowledgeBaseVisibilityType } from 'types/KnowledgeBaseVisibilityType'
import { Maybe } from 'types/Maybe'
import { Question } from 'api/types'
import { Translation } from 'types/Translation'

enum KnowledgeBaseContentFormType {
  PRESENTATION = 'PRESENTATION',
  TEST = 'TEST',
  QUIZ = 'QUIZ',
  SANDBOX_TEST = 'SANDBOX_TEST',
}

interface QuizSubForm {
  quizDuration?: boolean
  quantityOfRandomQuestions: number | null
  questions?: Question[]
  markWeight: number
  randomQuestionOrder: boolean
  showAnswers: boolean
  canGoBack: boolean
  difficulty?: number
}

interface PresentationTestSubForm {
  difficulty?: number
  timeline?: Addressable | null
  scene?: Addressable | null
}

type KnowledgeBaseContentFormFields = {
  type: KnowledgeBaseContentFormType.QUIZ
  name: string
  nameTranslations: Translation[]
  description: string
  descriptionTranslations: Translation[]
  destination: number | null
  knowledgeBaseFilesIds?: number[]
  image: File | string | null
  visibility: KnowledgeBaseVisibilityType
  duration: Maybe<number>
  subForm: QuizSubForm
}
| {
  type: KnowledgeBaseContentFormType.PRESENTATION
  name: string
  nameTranslations: Translation[]
  description: string
  descriptionTranslations: Translation[]
  destination: number | null
  knowledgeBaseFilesIds?: number[]
  image: File | string | null
  visibility: KnowledgeBaseVisibilityType
  duration: number
  subForm: PresentationTestSubForm
}
| {
  type: KnowledgeBaseContentFormType.TEST | KnowledgeBaseContentFormType.SANDBOX_TEST
  name: string
  nameTranslations: Translation[]
  description: string
  descriptionTranslations: Translation[]
  destination: number | null
  knowledgeBaseFilesIds?: number[]
  image: File | string | null
  visibility: KnowledgeBaseVisibilityType
  duration: number
  subForm: PresentationTestSubForm
}

export type {
  KnowledgeBaseContentFormFields,
  PresentationTestSubForm,
  QuizSubForm,
}

export {
  KnowledgeBaseContentFormType,
}
