import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress, Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router'
import { useQueryClient } from 'react-query'

import { useGetKnowledgeBaseEntityQuery } from 'api/queries/knowledgeBase/knowledgeBase'
import { KNOWLEDGE_BASE_ENTITY_QUERY_KEY } from 'api/queriesKeys'
import { useEditKnowledgeBaseObjectMutation } from 'api/mutations/knowledgeBase/knowledgeBase'
import { useDestination } from 'hooks/useDestination'
import { useLocationReturnFunction } from 'hooks/useLocationReturnFunction'
import { KnowledgeBaseContentForm, KnowledgeBaseContentFormFields } from 'components/forms/KnowledgeBaseContentForm/KnowledgeBaseContentForm'
import { useKnowledgeBaseFormImage } from 'hooks/useKnowledgeBaseFormImage'
import { getFormInitValues, parseContentFields } from './KnowledgeBaseContentEditScreen.utils'
import * as Styled from '../knowledgeBase.styles'

interface ParamTypes {
  id: string
}

const KnowledgeBaseContentEditScreen = (): JSX.Element => {
  const { t } = useTranslation()
  const { id } = useParams<ParamTypes>()
  const history = useHistory()
  const queryClient = useQueryClient()
  const handleReturn = useLocationReturnFunction()

  const {
    data: knowledgeBaseData,
    isLoading,
    isError,
  } = useGetKnowledgeBaseEntityQuery(+id, { refetchOnWindowFocus: false, enabled: !!id })

  const {
    mutate: editObjectMutate,
    isLoading: isEditMutationLoading,
  } = useEditKnowledgeBaseObjectMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries([KNOWLEDGE_BASE_ENTITY_QUERY_KEY, { id }])
      if (handleReturn) {
        handleReturn()
      } else {
        history.goBack()
      }
    },
  })

  const destinationText = useDestination(knowledgeBaseData)

  const formInitValues = useMemo(() => (
    knowledgeBaseData ? getFormInitValues(knowledgeBaseData) : undefined
  ), [knowledgeBaseData])

  const {
    isAddImageMutationLoading,
    isDeleteImageMutationLoading,
    handleSubmitImage
  } = useKnowledgeBaseFormImage(formInitValues)

  const isMutationsLoading = useMemo(() => (
    isEditMutationLoading || isAddImageMutationLoading || isDeleteImageMutationLoading
  ), [isAddImageMutationLoading, isDeleteImageMutationLoading, isEditMutationLoading])

  const handleSubmit = async (values: KnowledgeBaseContentFormFields, languageCode: string) => {
    const { image } = values
    await handleSubmitImage(image, +id)

    editObjectMutate({
      id: +id,
      dto: parseContentFields(values, languageCode)
    })
  }

  return (
    <Styled.RootContainer>
      {isError || isLoading ? (
        <Styled.HelpersContainer>
          {isError && <Typography color='error'>{t('common.errors.generic')}</Typography>}
          {isLoading && <CircularProgress color='primary' />}
        </Styled.HelpersContainer>
      ) : (
        <KnowledgeBaseContentForm
          initialValues={formInitValues}
          destinationPlaceholder={destinationText}
          onSubmit={handleSubmit}
          isLoading={isMutationsLoading}
        />
      )}
    </Styled.RootContainer>
  )
}

export { KnowledgeBaseContentEditScreen }
