import * as yup from 'yup'

import { GroupType } from 'types/GroupType'
import { KnowledgeBaseContentFormType } from 'components/forms/KnowledgeBaseContentForm/types'
import { KnowledgeBaseVisibilityType } from 'types/KnowledgeBaseVisibilityType'
import { ImageSchema } from './ImageSchema'

const ImagePathSchema = yup.string().nullable()

const PresentationOrTestSubFormSchema = yup.object().shape({
  difficulty: yup
    .number(),
  timeline: yup
    .object()
    .shape({
      id: yup.number(),
      filename: yup.string(),
      address: yup.string()
    })
    .nullable(),
  scene: yup
    .object()
    .shape({
      id: yup.number(),
      filename: yup.string(),
      address: yup.string()
    })
    .nullable(),
})

const TranslationObject = yup.object().shape({
  languageCode: yup.string(),
  value: yup.string()
})

const CreatePresentationOrTestSchema = yup.object().shape({
  type: yup
    .string()
    .oneOf([
      GroupType.PRESENTATION,
      GroupType.TEST,
      GroupType.SANDBOX_TEST
    ], 'knowledgeBase.errors.type.incorrect')
    .required('knowledgeBase.errors.type.required'),
  name: yup
    .string()
    .required('knowledgeBase.errors.name.required'),
  nameTranslations: yup.array().of(TranslationObject),
  descriptionTranslations: yup.array().of(TranslationObject),
  description: yup
    .string()
    .required('knowledgeBase.errors.description.required')
    .test(
      'descriptionLength',
      'knowledgeBase.errors.description.maxLength',
      (val) => !!val && val?.length < 1024
    ),
  destination: yup
    .number()
    .nullable(true),
  knowledgeBaseFilesIds: yup
    .array().of(yup.number())
    .required('knowledgeBase.errors.knowledgeBaseFilesIds.required'),
  image: yup.lazy((value) => {
    if (value == null || typeof value === 'string') {
      return ImagePathSchema
    }
    return ImageSchema
  }),
  visibility: yup
    .string()
    .oneOf([
      KnowledgeBaseVisibilityType.Author,
      KnowledgeBaseVisibilityType.Company,
      KnowledgeBaseVisibilityType.UsersList
    ], 'knowledgeBase.errors.visibility.incorrect')
    .required('knowledgeBase.errors.visibility.required'),
  duration: yup
    .number()
    .integer('knowledgeBase.errors.duration.integer')
    .min(1, 'knowledgeBase.errors.duration.min')
    .max(1440, 'knowledgeBase.errors.duration.max')
    .required('knowledgeBase.errors.duration.required'),
  subForm: PresentationOrTestSubFormSchema,
})

const EditPresentationOrTestSchema = CreatePresentationOrTestSchema

const QuizSubFormSchema = yup.object().shape({
  quantityOfRandomQuestions: yup
    .number()
    .nullable(true)
    .test(
      'quantityOfRandomQuestions',
      'knowledgeBase.errors.quantityOfRandomQuestions',
      (value, testCtx) => {
        const { parent: { questions } } = testCtx
        if (value) {
          return value <= questions?.length
        }
        return true
      },
    ),
  questions: yup
    .array(),
  markWeight: yup
    .number()
    .integer('knowledgeBase.errors.markWeight.integer')
    .min(1, 'knowledgeBase.errors.markWeight.min')
    .max(3, 'knowledgeBase.errors.markWeight.max')
    .required('knowledgeBase.errors.markWeight.required'),
  randomQuestionOrder: yup
    .boolean()
    .required('knowledgeBase.errors.randomQuestionOrder.required'),
  showAnswers: yup
    .boolean()
    .required('knowledgeBase.errors.showAnswers.required'),
  canGoBack: yup
    .boolean()
    .required('knowledgeBase.errors.canGoBack.required'),
})

const CreateQuizSchema = yup.object().shape({
  type: yup
    .string()
    .oneOf([GroupType.QUIZ], 'knowledgeBase.errors.type.incorrect')
    .required('knowledgeBase.errors.type.required'),
  name: yup.string().required('knowledgeBase.errors.name.required'),
  nameTranslations: yup.array().of(TranslationObject),
  descriptionTranslations: yup.array().of(TranslationObject),
  description: yup
    .string()
    .test(
      'descriptionLength',
      'knowledgeBase.errors.description.maxLength',
      (val) => (val ? val?.length < 1024 : true),
    ),
  destination: yup
    .number()
    .nullable(true),
  image: yup.lazy((value) => {
    if (value == null || typeof value === 'string') {
      return ImagePathSchema
    }
    return ImageSchema
  }),
  visibility: yup
    .string()
    .oneOf([
      KnowledgeBaseVisibilityType.Author,
      KnowledgeBaseVisibilityType.Company,
      KnowledgeBaseVisibilityType.UsersList
    ], 'knowledgeBase.errors.visibility.incorrect')
    .required('knowledgeBase.errors.visibility.required'),
  duration: yup
    .number()
    .integer('knowledgeBase.errors.duration.integer')
    .min(1, 'knowledgeBase.errors.duration.min')
    .max(1440, 'knowledgeBase.errors.duration.max')
    .nullable(true),
  subForm: QuizSubFormSchema,
})

const EditQuizSchema = CreateQuizSchema

const CreateKnowledgeBaseContentSchema = yup.lazy((value) => {
  const { type } = value
  if (type === KnowledgeBaseContentFormType.QUIZ) {
    return CreateQuizSchema
  }
  return CreatePresentationOrTestSchema
})

const EditKnowledgeBaseContentSchema = yup.lazy((value) => {
  const { type } = value
  if (type === KnowledgeBaseContentFormType.QUIZ) {
    return EditQuizSchema
  }
  return EditPresentationOrTestSchema
})

export {
  CreateKnowledgeBaseContentSchema,
  EditKnowledgeBaseContentSchema,
}
