import { KnowledgeBaseEntityResponse } from 'api/queries/knowledgeBase/types'
import { PRESENTATION, TEST, QUIZ, MIN_MARK_WEIGHT, SANDBOX_TEST } from 'constants/knowledgeBaseContent'
import { KnowledgeBaseContentFormFields } from 'components/forms/KnowledgeBaseContentForm/KnowledgeBaseContentForm'
import { KnowledgeBaseContentFormType } from 'components/forms/KnowledgeBaseContentForm/types'
import { EditKnowledgeBaseObjectVariables, DataType, VisibilityType } from 'api/mutations/knowledgeBase/types'

const getFormInitValues = (
  data?: KnowledgeBaseEntityResponse
): KnowledgeBaseContentFormFields | undefined => {
  if (data) {
    const { dataType } = data
    if (dataType === PRESENTATION) {
      return {
        type: KnowledgeBaseContentFormType.PRESENTATION,
        name: data.name,
        nameTranslations: data.nameTranslations,
        description: data.description,
        descriptionTranslations: data.descriptionTranslations,
        destination: data.parent?.id || null,
        knowledgeBaseFilesIds: data.knowledgeBaseFiles?.map((file) => file.id) || [],
        image: data.image?.miniaturePath || null,
        visibility: data.visibility,
        duration: data.duration ? data.duration / 60 : 0,
        subForm: {
          difficulty: data.difficulty,
          scene: data.scene,
          timeline: data.timeline,
        }
      }
    }
    if (dataType === TEST) {
      return {
        type: KnowledgeBaseContentFormType.TEST,
        name: data.name,
        nameTranslations: data.nameTranslations,
        description: data.description,
        descriptionTranslations: data.descriptionTranslations,
        destination: data.parent?.id || null,
        knowledgeBaseFilesIds: data.knowledgeBaseFiles?.map((file) => file.id) || [],
        image: data.image?.miniaturePath || null,
        visibility: data.visibility,
        duration: data.duration ? data.duration / 60 : 0,
        subForm: {
          difficulty: data.difficulty,
          scene: data.scene,
          timeline: data.timeline,
        }
      }
    }
    if (dataType === SANDBOX_TEST) {
      return {
        type: KnowledgeBaseContentFormType.SANDBOX_TEST,
        name: data.name,
        nameTranslations: data.nameTranslations,
        description: data.description,
        descriptionTranslations: data.descriptionTranslations,
        destination: data.parent?.id || null,
        knowledgeBaseFilesIds: data.knowledgeBaseFiles?.map((file) => file.id) || [],
        image: data.image?.miniaturePath || null,
        visibility: data.visibility,
        duration: data.duration ? data.duration / 60 : 0,
        subForm: {
          difficulty: data.difficulty,
          scene: data.scene,
          timeline: data.timeline,
        }
      }
    }
    if (dataType === QUIZ) {
      return {
        type: KnowledgeBaseContentFormType.QUIZ,
        name: data.name,
        nameTranslations: data.nameTranslations,
        description: data.description,
        descriptionTranslations: data.descriptionTranslations,
        destination: data.parent?.id || null,
        knowledgeBaseFilesIds: data.knowledgeBaseFiles?.map((file) => file.id),
        image: data.image?.miniaturePath || null,
        visibility: data.visibility,
        duration: data.duration && data.duration / 60,
        subForm: {
          canGoBack: data.canGoBack || false,
          markWeight: data.markWeight || MIN_MARK_WEIGHT,
          randomQuestionOrder: data.randomQuestionOrder || false,
          showAnswers: data.showAnswers || false,
          quantityOfRandomQuestions: data.quantityOfRandomQuestions || null,
          questions: data.questions,
          quizDuration: !!data.duration,
        }
      }
    }
  }
  return undefined
}

const parseContentFields = (
  variables: KnowledgeBaseContentFormFields,
  languageCode: string
): EditKnowledgeBaseObjectVariables['dto'] => {
  const {
    destination,
    type,
    image,
    visibility,
    duration,
    knowledgeBaseFilesIds,
    subForm,
    ...values
  } = variables

  return {
    ...values,
    languageCode,
    knowledgeBaseFileIds: knowledgeBaseFilesIds,
    parentId: destination !== null ? destination.toString() : destination,
    dataType: type as DataType,
    visibility: visibility as VisibilityType,
    duration: duration && duration * 60,
    ...(typeof image !== 'string' && image !== null ? { image } : {}),
    ...('scene' in subForm
      ? {
        sceneId: subForm.scene?.id,
        timelineId: subForm.timeline?.id,
        difficulty: subForm.difficulty
      }
      : { ...Object.fromEntries(
        Object.entries(subForm).filter(([key]) => ['timeline', 'scene'].includes(key))
      )
      }
    ),
  }
}

export { getFormInitValues, parseContentFields }
