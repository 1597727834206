import { AddKnowledgeBaseGroupVariables } from 'api/mutations/knowledgeBase/types'
import { KnowledgeBaseContentResponse } from 'api/queries/knowledgeBase/types'

const mapKnowledgeBasePages = (pages: KnowledgeBaseContentResponse[]) => (
  pages.map((page) => page.content.map(({ image, ...item }) => ({
    ...item,
    ...(image ? { image: image.miniaturePath } : {}),
  })))
)

const parseGroupFields = (name: string, id: number | null): AddKnowledgeBaseGroupVariables => ({
  dataType: 'GROUP',
  name,
  parentId: id
})

export { mapKnowledgeBasePages, parseGroupFields }
