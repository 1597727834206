import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined'

import { GROUP_ICON, PRESENTATION_ICON, TEST_ICON, QUIZ_ICON, GROUP } from 'constants/knowledgeBaseContent'
import presentationPlaceholderImage from 'assets/images/presentation-image-nobg.png'
import testPlaceholderImage from 'assets/images/test-image-nobg.png'
import quizPlaceholderImage from 'assets/images/quiz-image-nobg.png'
import groupPlaceholderImage from 'assets/images/group.png'
import { formatDate } from 'utils/formatDate'
import { RestrictedContent } from 'components/auth/RestrictedContent/RestrictedContent'
import { ADMIN_ROLE } from 'constants/roleGroups'
import { KnowledgeBaseContentType } from 'types/KnowledgeBaseContent'
import * as Styled from './Card.styles'

type CardDataType = KnowledgeBaseContentType

interface CardProps {
  title: string,
  image?: string,
  creationDateTime: string,
  dataType?: CardDataType,
  onClick: () => void
  onEdit?: () => void
  onMove: () => void
  onDelete?: () => void
  onCopy?: () => void
}

const Card: React.FC<CardProps> = ({
  image,
  title,
  creationDateTime,
  dataType = 'GROUP',
  onClick: handleClick,
  onEdit: handleEdit,
  onMove,
  onDelete: handleDelete,
  onCopy: handleCopy,
}) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const formattedDate = formatDate(creationDateTime)

  const placeholderImage = useMemo(() => {
    switch (dataType) {
      case 'PRESENTATION':
        return presentationPlaceholderImage
      case 'TEST':
        return testPlaceholderImage
      case 'QUIZ':
        return quizPlaceholderImage
      default:
        return groupPlaceholderImage
    }
  }, [dataType])

  const ContentIcon = useMemo(() => {
    switch (dataType) {
      case 'PRESENTATION':
        return PRESENTATION_ICON
      case 'TEST':
        return TEST_ICON
      case 'QUIZ':
        return QUIZ_ICON
      default:
        return GROUP_ICON
    }
  }, [dataType])

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMove = () => {
    handleClose()
    onMove()
  }

  return (
    <Grid item>
      <Styled.BackCard>
        <Styled.Wrapper $image={image || placeholderImage} $isPlaceholder={!image}>
          <Styled.Header>
            <RestrictedContent accessRoles={[ADMIN_ROLE]}>
              <Styled.OpenMenuButton
                aria-controls='simple-menu'
                aria-haspopup='true'
                onClick={handleOpen}
              >
                <Styled.MoreVertIcon />
              </Styled.OpenMenuButton>
              <Styled.Menu
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClick={handleClose}
                onClose={handleClose}
              >
                {dataType
                && (
                  <div>
                    <Styled.MenuItem onClick={handleMove}>
                      <ListItemIcon>
                        <FolderOpenIcon />
                      </ListItemIcon>
                      <ListItemText primary={t('common.move')} />
                    </Styled.MenuItem>
                    <Styled.MenuItem onClick={handleEdit || handleClose}>
                      <ListItemIcon>
                        <EditOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary={t('common.edit')} />
                    </Styled.MenuItem>
                  </div>
                )}
                <Styled.MenuItem onClick={handleDelete}>
                  <ListItemIcon>
                    <DeleteOutlineIcon />
                  </ListItemIcon>
                  <Styled.ListItemTextDelete primary={t('common.delete')} />
                </Styled.MenuItem>
                {dataType === 'QUIZ' && (
                  <Styled.MenuItem onClick={handleCopy}>
                    <ListItemIcon>
                      <FileCopyOutlined />
                    </ListItemIcon>
                    <ListItemText primary={t('common.copy')} />
                  </Styled.MenuItem>
                )}
              </Styled.Menu>
            </RestrictedContent>
          </Styled.Header>
          <Styled.Data>
            <Styled.CircleContainer>
              <Styled.Circle>
                <Styled.ExpandMoreIcon />
              </Styled.Circle>
            </Styled.CircleContainer>
            <Styled.Content onClick={handleClick}>
              {dataType === 'GROUP' ? (
                <Styled.GroupTitle>{title}</Styled.GroupTitle>
              ) : (
                <Styled.Title>
                  {`${t(`group.${[dataType]}`)} - ${title}`}
                </Styled.Title>
              )}
              <IconButton>
                <ContentIcon
                  width={24}
                  height={24}
                  viewBox={dataType !== GROUP ? '0 0 120 120' : '0 0 24 24'}
                />
              </IconButton>
            </Styled.Content>
            <Styled.CreatedDateText>
              {t('common.created')} : {formattedDate}
            </Styled.CreatedDateText>
          </Styled.Data>
        </Styled.Wrapper>
      </Styled.BackCard>
    </Grid>
  )
}

export { Card }
export type { CardDataType }
