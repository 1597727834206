import { useParams } from 'react-router'

import { useCreateKnowledgeBaseContent } from 'hooks/useCreateKnowledgeBaseContent'
import { useGetKnowledgeBaseEntityQuery } from 'api/queries/knowledgeBase/knowledgeBase'
import { KnowledgeBaseContentForm, KnowledgeBaseContentFormFields } from 'components/forms/KnowledgeBaseContentForm/KnowledgeBaseContentForm'
import { useDestination } from 'hooks/useDestination'
import { useKnowledgeBaseFormImage } from 'hooks/useKnowledgeBaseFormImage'
import { useCompanyId } from 'providers/CompanyProvider'
import { parseContentFields } from './KnowledgeBaseContentCreateScreen.utils'
import * as Styled from '../knowledgeBase.styles'

interface ParamTypes {
  id: string
}

const KnowledgeBaseContentCreateScreen = (): JSX.Element => {
  const { id } = useParams<ParamTypes>()
  const companyId = useCompanyId() || 0

  const { data: knowledgeBaseData } = useGetKnowledgeBaseEntityQuery(+id, { enabled: !!id })
  const {
    isAddImageMutationLoading,
    isDeleteImageMutationLoading,
    handleSubmitImage
  } = useKnowledgeBaseFormImage()

  const {
    mutateAsync: addObjectMutate,
    isLoading: isCreateKnowledgeBaseLoading,
  } = useCreateKnowledgeBaseContent()

  const destinationText = useDestination(knowledgeBaseData, { includeItself: true })

  const handleSubmit = async (variables: KnowledgeBaseContentFormFields, languageCode: string) => {
    const { image } = variables
    const values = parseContentFields(variables, languageCode)

    const response = await addObjectMutate({ companyId, ...values })
    if (response.id) {
      await handleSubmitImage(image, response.id)
    }
  }

  const isLoading = isCreateKnowledgeBaseLoading
    || isAddImageMutationLoading
    || isDeleteImageMutationLoading

  return (
    <Styled.RootContainer>
      <KnowledgeBaseContentForm
        onSubmit={handleSubmit}
        destinationPlaceholder={destinationText}
        initialDestination={knowledgeBaseData?.id}
        isLoading={isLoading}
      />
    </Styled.RootContainer>
  )
}

export { KnowledgeBaseContentCreateScreen }
