import { AddKnowledgeBaseObjectVariables, DataType, VisibilityType } from 'api/mutations/knowledgeBase/types'
import { KnowledgeBaseContentFormFields } from 'components/forms/KnowledgeBaseContentForm/KnowledgeBaseContentForm'

const parseContentFields = (
  variables: KnowledgeBaseContentFormFields,
  languageCode: string
): AddKnowledgeBaseObjectVariables => {
  const {
    destination,
    type,
    visibility,
    duration,
    knowledgeBaseFilesIds,
    subForm,
    ...values
  } = variables

  return {
    ...values,
    languageCode,
    knowledgeBaseFileIds: knowledgeBaseFilesIds,
    parentId: destination !== null ? destination.toString() : destination,
    dataType: type as DataType,
    visibility: visibility as VisibilityType,
    duration: duration && duration * 60,
    ...('scene' in subForm
      ? {
        sceneId: subForm.scene?.id,
        timelineId: subForm.timeline?.id,
        difficulty: subForm.difficulty
      }
      : { ...Object.fromEntries(
        Object.entries(subForm).filter(([key]) => ['timeline', 'scene'].includes(key))
      )
      }
    ),
  }
}

export { parseContentFields }
