import { ChangeEvent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MenuItem } from '@material-ui/core'

import { useSetCompanyId, useCompanyId } from 'providers/CompanyProvider'
import { useGetAllCompaniesQuery } from 'api/queries/companies/companies'
import { SelectProps } from 'components/inputs/Select/Select'
import * as Styled from './CompanySelect.styles'

interface CompanySelectProps extends SelectProps {
  shouldDisplay?: boolean
}

const CompanySelect = ({
  shouldDisplay = true,
  ...rest
}: CompanySelectProps) => {
  const { t } = useTranslation()
  const companyId = useCompanyId()
  const handleSetCompany = useSetCompanyId()

  const {
    data: queryData,
    isLoading,
    isError,
  } = useGetAllCompaniesQuery()

  useEffect(() => {
    handleSetCompany(queryData?.data[0].id)
  }, [handleSetCompany, queryData?.data])

  const handleChange = (event: ChangeEvent<{
    name?: string | undefined
    value: unknown
  }>) => {
    handleSetCompany(event.target.value as number)
  }

  if (!shouldDisplay) {
    return null
  }

  return (
    <Styled.RootContainer>
      {isLoading ? (
        <Styled.CircularProgress />
      ) : (
        isError ? (
          <Styled.ErrorMessage>{t('common.errors.generic')}</Styled.ErrorMessage>
        ) : (
          <Styled.Select
            defaultValue={queryData?.data[0].id}
            value={companyId}
            onChange={handleChange}
            {...rest}
          >
            <MenuItem disabled value={0}>
              {t('common.companies')}
            </MenuItem>
            {queryData?.data.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name} ({t('common.id')}: {id})
              </MenuItem>
            ))}
          </Styled.Select>
        )
      )}
    </Styled.RootContainer>
  )
}

export { CompanySelect }
