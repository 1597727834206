/* eslint-disable max-len */
import {
  ALPHABETICAL, ALPHABETICAL_REVERSE, NEWEST, NONE as SORT_NONE, OLDEST
} from 'constants/sortTypes'
import { AUTHOR, COMPANY, PUBLIC } from 'constants/visibilityTypes'
import { ADMIN_ROLE, COMPANY_ROLE, TEACHER_ROLE, PARTICIPANT_ROLE } from 'constants/roleGroups'
import { EMAIL_AWAIT_CONFIRMATION } from 'constants/errorCodes'
import { ResultsGroupingType } from 'constants/resultsGroupingType'
import { parseErrorCodeToi18nKey } from 'utils/parseErrorCodeToi18nKey'
import { NotificationType } from 'types/Notifications'
import { EducationLevel } from 'types/EducationLevel'
import { RankingScopeType } from 'types/RankingScopeType'
import { QuizQuestionType } from 'types/QuestionType'
import { GroupType } from 'types/GroupType'
import { AnalysisScopeType } from 'types/AnalysisScopeType'
import { AppointmentVisibilityType } from 'types/AppointmentVisibilityType'
import { KnowledgeBaseVisibilityType } from 'types/KnowledgeBaseVisibilityType'

export default {
  words: {
    quiz_0: 'Quiz',
    quiz_1: 'Quizy',
    quiz_2: 'Quizów',
    minutes_0: 'minutę',
    minutes_1: 'minuty',
    minutes_2: 'minut',
    occurrence_0: 'Wystąpienie',
    occurrence_1: 'Wystąpienia',
    occurrence_2: 'Wystąpnień',
  },
  translation: {
    common: {
      id: 'Id',
      emailOrUsername: 'E-mail lub nazwa użytkownika',
      username: 'Nazwa użytkownika',
      email: 'E-mail',
      password: 'Hasło',
      confirmPassword: 'Potwierdź hasło',
      newPassword: 'Nowe hasło',
      confirmNewPassword: 'Potwierdź nowe hasło',
      oldPassword: 'Stare hasło',
      comingSoon: 'Funkcjonalność dostępna wkrótce',
      firstName: 'Imię',
      lastName: 'Nazwisko',
      phoneNumber: 'Numer telefonu',
      userColor: 'Kolor użytkownika',
      educationLevel: 'Wykształcenie',
      timeZone: 'Strefa czasowa',
      signIn: 'Zaloguj',
      signUp: 'Zarejestruj',
      signOut: 'Wyloguj',
      save: 'Zapisz',
      saveChanges: 'Zapisz zmiany',
      saving: 'Zapisywanie',
      saved: 'Zapisano',
      created: 'Utworzono',
      loading: 'Ładowanie',
      seeAll: 'Zobacz wszystkie',
      go: 'Przejdź',
      details: 'Dane osobowe',
      language: 'Język',
      edit: 'Edytuj',
      move: 'Przenieś',
      delete: 'Usuń',
      copy: 'Kopiuj',
      group: 'Grupa',
      sort: 'Sortuj',
      search: 'Szukaj',
      arrange: 'Zorganizuj',
      create: 'Stwórz',
      createNew: 'Stwórz nowe',
      choose: 'Wybierz',
      ok: 'Potwierdź',
      cancel: 'Anuluj',
      type: 'Typ',
      download: 'Pobierz',
      name: 'Nazwa',
      description: 'Opis',
      visibility: 'Widoczność',
      chooseImage: 'Wybierz zdjęcie',
      chooseFile: 'Wybierz plik',
      noFileSelected: 'Nie wybrano pliku',
      add: 'Dodaj',
      minutes: 'Minut',
      roles: 'Role',
      start: 'Start',
      actions: 'Akcje',
      emptySubtext: 'By stworzyć nowe, użyj powyższych przycisków',
      deactivate: 'Dezaktywuj',
      activate: 'Aktywuj',
      companies: 'Firmy',
      agree: 'Zgadzam się',
      decline: 'Nie zgadzam się',
      users: 'Użytkownicy',
      startWrite: 'Wpisz nazwę aby wyszukać...',
      notAssigned: 'Nie przypisano',
      at: 'o',
      noData: 'Brak danych',
      yes: 'Tak',
      no: 'Nie',
      accountExpirationDate: 'Data wygaśnięcia konta',
      filter: 'Filtruj',
      iUnderstand: 'Rozumiem',
      noOptions: 'Brak opcji',
      errors: {
        generic: 'Wystąpił błąd.',
        firstName: {
          required: 'Imię jest wymagane',
        },
        lastName: {
          required: 'Nazwisko jest wymagane',
        },
        username: {
          taken: 'Nazwa użytkownika jest zajęta',
          min: 'Nazwa użytkownika musi mieć minimum 4 znaki',
          max: 'Nazwa użytkownika może mieć maksymalnie 20 znaków',
          invalid: 'Znaki specjalne są niedozwolone',
        },
        emailOrUsername: {
          required: 'E-mail lub nazwa użytkownika jest wymagana',
          invalid: 'Wprowadź poprawny adres email lub nazwę użytkownika',
        },
        email: {
          required: 'E-mail jest wymagany',
          invalid: 'Wprowadź poprawny adres e-mail',
        },
        password: {
          min: 'Hasło musi mieć minimum 8 znaków',
          required: 'Hasło jest wymagane',
        },
        confirmPassword: {
          dontMatch: 'Te hasła nie pasują do siebie',
          required: 'Potwierdzenie hasła jest wymagane',
        },
        phoneNumber: {
          required: 'Numer telefonu jest wymagany',
          invalid: 'Nieprawidłowy numer telefonu',
          min: 'Numer musi mieć minimum 9 znaków'
        },
        userColor: {
          invalid: 'Nieprawidłowy format koloru',
        },
        roles: {
          required: 'Rola jest wymagana'
        },
        imageSize: 'Plik jest za duży, maksymalny rozmiar pliku to 2MB',
        fileType: 'Niewspierany format pliku',
        imageDimensions: 'Niepoprawny rozmiar zdjęcia, minimalny rozmiar to 500px x 300px'
      },
    },
    signIn: {
      errors: {
        wrongCredentials: 'Podana nazwa użytkownika lub hasło są nieprawidłowe',
      },
      forgotPassword: 'Nie pamiętasz hasła?',
      dontHaveAccount: 'Nie masz jeszcze konta? Zarejestruj się',
    },
    signUp: {
      form: {
        username: 'Nazwa użytkownika(opcjonalnie)',
        privacyPolicy: 'Akceptuję warunki korzystania z serwisu',
      },
      errors: {
        privacyPolicy: {
          required: 'Musisz zaakceptować warunki korzystania z serwisu',
        },
      },
      haveAccount: 'Masz już konto? Zaloguj się',
      backToLogin: 'Powrót do okna logowania',
    },
    resetPassword: {
      form: {
        newPassword: 'Nowe hasło',
        confirmNewPassword: 'Potwierdź nowe hasło',
      },
      sendResetEmail: 'Wyślij e-mail resetujący hasło',
      emailSent: 'Wysłano e-mail resetujący hasło na adres ',
      savePassword: 'Zapisz hasło',
      savedPassword: 'Nowe hasło zostało zapisane',
    },
    changeEmail: {
      title: 'Zmień adres e-mail',
      sent: 'Mail potwierdzajacy wyslany',
      action: {
        success: 'E-mail został zaktualizowany',
        error: 'Wystąpił błąd podczas próby zmiany e-maila',
      },
    },
    confirmEmail: {
      confirm: 'Potwierdź nowy e-mail',
      success: 'Pomyślnie zmieniono adres e-mail',
      return: 'Wróć do ekranu głównego',
    },
    changePassword: {
      title: 'Zmień hasło',
      form: {
        newPassword: 'Nowe hasło',
        confirmNewPassword: 'Potwierdź nowe hasło',
        oldPassword: 'Potwierdź zmianę aktualnym hasłem',
      },
      errors: {
        oldPassword: {
          required: 'Stare hasło jest wymagane'
        },
        newPassword: {
          min: 'Nowe hasło musi mieć minimum 8 znaków',
          required: 'Nowe hasło jest wymagane'
        },
        confirmNewPassword: {
          required: 'Potwierdzenie nowego hasła jest wymagane'
        },
      },
      action: {
        success: 'Hasło zostało zaktualizowane',
        error: 'Wystąpił błąd podczas próby zmiany hasła',
      },
    },
    changeUserDetails: {
      action: {
        success: 'Dane osobowe zostały zaktualizowane',
        error: 'Wystąpił błąd podczas próby zmiany danych osobowych',
      },
    },
    drawer: {
      navigation: {
        home: 'Strona główna',
        base: 'Baza',
        companies: 'Firmy',
        users: 'Użytkownicy',
        knowledgeBase: 'Baza wiedzy',
        appointments: 'Spotkania',
        results: 'Wyniki',
        statistics: 'Statystyka',
        analysis: 'Analiza',
        courses: 'Kursy',
        account: 'Konto',
        faq: 'FAQ',
      },
    },
    account: {
      colorNotSet: 'Brak',
      userDetails: 'Dane osobowe',
      credentials: 'Dane logowania',
      languageAndNotifications: 'Język i powiadomienia'
    },
    appointments: {
      add: 'Dodaj nowe spotkanie',
      nextAppointments: 'Najbliższe spotkania',
      archivedAppointments: 'Poprzednie spotkania',
      emptyNext: 'Brak spotkań w nabliższym czasie',
      emptyArchived: 'Brak archiwalnych spotkań',
      searchPlaceholder: 'Wpisz nazwę aby wyszukać...',
    },
    appointmentList: {
      seeDetails: 'Zobacz szczegóły',
      contentDownloadedTooltip: 'Zawartość możesz pobrać w szczegółach spotkania',
      contentDownloaded: 'Jesteś na bieżąco',
      contentNotDownloaded: 'Musisz pobrać zawartość',
    },
    appointment: {
      title: 'Spotkanie',
      addDescriptionPlaceholder: 'Podaj opis spotkania',
      lecturer: 'Prowadzący',
      lecturerPlaceholder: 'Podaj wykładowców spotkania',
      duration: 'Czas trwania',
      content: 'Zawartość',
      addContent: 'Dodaj zawartość',
      participants: 'Uczestnicy',
      addParticipants: 'Dodaj uczestników',
      date: 'Data',
      timeOfAppointment: 'Czas trwania spotkania',
      additionalMaterials: 'Materiały dodatkowe',
      addAdditionalMaterials: 'Dodaj/Edytuj materiały dodatkowe',
      additionalMaterialsHelperText: 'Możesz dodać tylko pliki pdf lub wideo',
      noAdditionalMaterials: 'Brak materiałów dodatkowych',
      sendNotifications: 'Wyślij do uczestników powiadomienia o nowym spotkaniu',
      noParticipants: 'To spotkanie nie posiada żadnych uczestników',
      noKnowledgeBaseSchedule: 'To spotkanie nie posiada żadnych elementów z bazy wiedzy',
      progressDownloadingContent: 'Postęp pobierania zawartości',
      addChosen: 'Dodaj wybrane',
      open: 'Otwarte',
      isOpen: 'Spotkanie jest otwarte',
      isNotOpen: 'Spotkanie nie jest otwarte',
      visibility: {
        [AppointmentVisibilityType.Private]: 'Prywatne',
        [AppointmentVisibilityType.Public]: 'Otwarte',
        [AppointmentVisibilityType.Invitational]: 'Na zaproszenie',
        info: {
          [AppointmentVisibilityType.Private]: 'Spotkanie jest widoczne tylko dla autora',
          [AppointmentVisibilityType.Invitational]: 'Spotkanie jest widoczne tylko dla użytkowników, którzy zostaną dodani do listy uczestników',
          [AppointmentVisibilityType.Public]: 'Spotkanie jest widoczne zarówno dla użytkowników dodanych do listy uczestników, jak i pozostałych członków firmy',
        }
      },
      errors: {
        name: 'Nazwa spotkania jest wymagana',
        duration: 'Czas zakończenia spotkania musi być później niż czas jego rozpoczęcia',
        dateTime: {
          date: {
            invalid: 'Nieprawidłowa data spotkania',
          },
          time: {
            invalid: 'Nieprawidłowy czas spotkania',
          },
        },
        participants: {
          selectedTeacher: 'Nie możesz przypisać wybranego wykładowcy jako uczestnika',
        },
        lecturers: {
          required: 'Wymagane jest podanie prowadzącego',
          selectedParticipant: 'Użytkownik jest już wybrany jako uczestnik spotkania',
        }
      },
      action: {
        success: {
          add: 'Spotkanie "{{ name }}" zostało dodane',
          edit: 'Spotkanie "{{ name }}" zostało zaktualizowane',
          createFile: 'Plik "{{ name }}" został utworzony',
          createLink: 'Link "{{ name }}" został utworzony',
          delete: 'Spotkanie zostało usunięte'
        },
        error: {
          add: 'Wystąpił błąd podczas próby dodania spotkania "{{ name }}"',
          edit: 'Wystąpił błąd podczas próby edycji spotkania',
          createFile: 'Wystąpił błąd podczas próby utworzenia pliku',
          createLink: 'Wystąpił błąd podczas próby utworzenia linku',
          delete: 'Wystąpił błąd podczas próby usunięcia spotkania'
        },
      },
      forum: {
        title: 'Forum',
        open: 'Otwórz',
        send: 'Wyślij',
        addNewEntry: 'Dodaj nowy wpis',
        continueThread: 'Kontynuuj wątek',
        goToThread: 'Przejdź do wątku',
        goBackToList: 'Wróć do listy',
        replies: 'Odpowiedzi',
        writeMessage: 'Napisz wiadomość...',
        writeReply: 'Napisz odpowiedź...',
        noEntries: 'Brak wpisów',
        action: {
          success: {
            add: 'Wpis na forum został dodany',
            edit: 'Wpis na forum został zmodyfikowany',
          },
          error: {
            add: 'Wystąpił błąd podczas próby dodania wpisu na forum',
            edit: 'Wystąpił błąd poczas próby edycji wpisu na forum',
          },
        },
      },
    },
    knowledgeBase: {
      root: 'Baza wiedzy (ogólny)',
      group: 'Grupa',
      presentation: 'Prezentacja',
      content: 'Zawartość',
      duration: 'Przybliżony czas trwania',
      difficulty: 'Przybliżona trudność',
      timeline: 'Timeline',
      scene: 'Scena',
      createNew: 'Stwórz nowy materiał',
      newGroup: 'Stwórz nową grupę',
      editGroup: 'Edytuj grupę',
      empty: 'Brak zawartości bazy wiedzy',
      delete: {
        groupConfirmSubText: 'Usuwając ten element, usuniesz również wszystko wewnątrz.',
        error: 'Błąd: Ten element lub element wewnątrz może być w użyciu',
      },
      copy: {
        confirmTitle: 'Potwierdź kopiowanie',
        quizCopySubText: 'Utworzony zostanie drugi, identyczny quiz w tej lokacji',
      },
      breadcrumbs: {
        root: 'Baza wiedzy',
        error: 'Nie udało się załadować nawigacji breadcrumbs, proszę skorzystać z nawigacji przeglądarki',
      },
      emptyPhoto: 'Brak zdjęcia',
      files: 'Pliki',
      destination: 'Miejsce utworzenia zawartości',
      photo: 'Zdjęcie',
      canGoBack: 'Możliwość cofania się',
      quizDuration: 'Czas trwania quizu',
      showAnswers: 'Pokaż odpowiedzi po zatwierdzeniu',
      randomQuestionOrder: 'Losowa kolejność pytań',
      markWeight: 'Waga oceny',
      randomQuestions: 'Liczba pytań wybieranych z puli',
      questionPool: 'Pula pytań',
      noQuestions: 'Do tego quizu nie dodano żadnych pytań',
      newQuestion: 'Dodaj nowe pytanie',
      translations: 'Tłumaczenia',
      visibility: {
        [KnowledgeBaseVisibilityType.Author]: 'Autor',
        [KnowledgeBaseVisibilityType.Company]: 'Firma',
        [KnowledgeBaseVisibilityType.UsersList]: 'Uprawnieni użytkownicy',
      },
      errors: {
        name: {
          required: 'Nazwa zawartości jest wymagana',
        },
        description: {
          required: 'Opis jest wymagany',
          maxLength: 'Opis jest za długi'
        },
        type: {
          required: 'Typ zawartości jest wymagany',
          incorrect: 'Niepoprawny typ zawartości',
        },
        files: {
          min: 'Minimalnie jeden plik jest wymagany',
          size: 'Plik jest za duży, maksymalny rozmiar pliku to 10MB',
        },
        destination: {
          required: 'Miejsce docelowe jest wymagane',
        },
        visibility: {
          required: 'Widoczność jest wymagana',
          incorrect: 'Niepoprawny typ widoczności',
        },
        move: {
          location: 'Wybierz inną lokalizację'
        },
        duration: {
          integer: 'Czas trwania musi być liczbą całkowitą',
          required: 'Czas trwania musi być podany',
          min: 'Czas trwania nie może być krótszy niż 1',
          max: 'Czas trwania nie może być dłuższy niż dzień'
        },
        questionWeight: {
          integer: 'Waga oceny musi być liczbą całkowitą',
          required: 'Waga oceny musi być podana',
          min: 'Waga oceny nie może być mniejsza niż 1',
          max: 'Waga oceny nie może być większa niż 3'
        },
        quantityOfRandomQuestions: 'Liczba pytań wybieranych z puli nie może być większa niż całkowita liczba pytań w puli ({{ count }})',
      },
      action: {
        success: {
          add: 'Zawartość "$t(group.{{ type }}) - {{ name }}" została dodana do bazy wiedzy',
          edit: 'Zawartość "$t(group.{{ type }}) - {{ name }}" została zaktualizowana',
          delete: 'Zawartość bazy wiedzy została usunięta',
          move: 'Zawartość bazy wiedzy została przeniesiona',
        },
        error: {
          add: 'Wystąpił błąd podczas próby dodania zawartości do bazy wiedzy',
          edit: 'Wystąpił błąd podczas próby edycji zawartości bazy wiedzy',
          delete: 'Wystąpił błąd podczas próby usunięcia zawartości bazy wiedzy',
          addImage: 'Wystąpił błąd podczas próby dodania obrazu zawartości do bazy wiedzy',
          move: 'Wystąpił błąd podczas próby przeniesienia zawartości bazy wiedzy',
        },
      },
    },
    quiz: {
      question: 'Treść pytania',
      stepId: 'Id kroku',
      weight: 'Waga pytania',
      addAnswer: 'Dodaj odpowiedź',
      labelAnswers: 'Odpowiedzi',
      addQuestion: 'Dodaj pytanie',
      presentation: 'Prezentacja której dotyczy pytanie',
      selectPresentation: 'Wybierz prezentację',
      manageQuestions: 'Zarządzaj pytaniami',
      action: {
        success: {
          copy: 'Quiz został skopiowany',
          add: 'Pytanie zostało dodane',
          edit: 'Pytanie zostało zaktualizowane',
          delete: 'Pytanie zostało usunięte',
          moveQuestion: 'Kolejność pytań została zaktualizowana'
        },
        error: {
          copy: 'Wystąpił błąd podczas próby kopiowania quizu',
          add: 'Wystąpił błąd podczas próby dodania pytania',
          edit: 'Wystąpił błąd podczas próby aktualizacji pytania',
          delete: 'Wystąpił błąd podczas próby usunięcia pytania',
          moveQuestion: 'Wystąpił błąd podczas próby zmiany kolejności pytań'
        },
      },
      errors: {
        type: {
          required: 'Typ zawartości jest wymagany',
          incorrect: 'Niepoprawny typ zawartości',
        },
        question: {
          required: 'Treść pytania jest wymagana',
        },
        weight: {
          integer: 'Waga pytania musi być liczbą całkowitą',
          required: 'Waga pytania musi być podana',
          min: 'Waga pytania nie może być mniejsza niż 1',
          max: 'Waga pytania nie może być większa niż 5',
          invalid: 'Waga musi być liczbą z przedziału od 1 do 5',
        },
        answers: {
          minFields: 'Nie można zapisać quizu bez odpowiedzi',
          minCorrect: 'Musisz zaznaczyć poprawną odpowiedź przed zapisaniem',
          content: {
            required: 'Treść odpowiedzi musi być podana',
          }
        },
        stepId: {
          invalid: 'Nieprawidłowe id kroku'
        },
      },
    },
    users: {
      addNew: 'Dodaj nowego użytkownika',
      cantEdit: 'Nie można edytować roli własnego konta',
      noUsers: 'Brak użytkowników',
      knowledgeBasePermissions: 'Uprawnienia bazy wiedzy',
      technical: 'Użytkownik techniczny',
      accountExpirationDateNotSet: 'Nie ustawiono',
      emailWaitingForConfirmation: 'Nowy e-mail oczekuje na potwierdzenie: {{ newEmail }}',
      confirmEmailChangeModal: {
        title: 'Potwierdź zmianę adresu e-mail',
        subText: 'Zmiana adresu e-mail użytkownika wymaga potwierdzenia nowego adresu w wiadomości wysłanej na podany adres',
      },
      action: {
        success: {
          setActive: 'Status użytkownika został zaktualizowany',
          editRole: 'Rola użytkownika została zaktualizowana',
          registerCompanyMember: 'Użytkownik został zarejestrowany do firmy',
          educationLevel: 'Poziom wykształcenia użytkownika został zaktualizowany',
          knowledgeBasePermissions: 'Uprawnienia bazy wiedzy zostały zaktualizowane',
          delete: 'Użytkownik został usunięty',
          edit: 'Dane użytkownika zostały zaktualizowane'
        },
        error: {
          setActive: 'Wystąpił błąd podczas próby zmiany statusu użytkownika',
          editRole: 'Wystąpił błąd podczas próby zmiany roli użytkownika',
          registerCompanyMember: 'Wystąpił błąd podczas próby rejestracji użytkownika',
          educationLevel: 'Wystąpił błąd podczas próby zmiany poziomu wykształcenia użytkownika',
          knowledgeBasePermissions: 'Wystąpił błąd podczas próby zmiany uprawnień bazy wiedzy',
          delete: 'Wystąpił błąd podczas próby usunięcia użytkownika',
          edit: 'Wystąpił błąd podczas próby edycji użytkownika'
        },
      },
    },
    results: {
      title: 'Wyniki',
      employeeRanking: 'Ranking pracowników',
      groupBy: 'Grupuj po',
      grouping: {
        [ResultsGroupingType.BY_APPOINTMENTS]: 'spotkaniach',
        [ResultsGroupingType.BY_QUIZZES]: 'quizach',
        [ResultsGroupingType.BY_USERS]: 'użytkownikach',
      },
      content: 'Treść pytania',
      answer: 'Odpowiedź',
      correctAnswer: 'Poprawna odpowiedź',
      accuracy: 'Poprawność',
      receivedAnswers: 'Uzyskane odpowiedzi',
      answeringAccuracy: 'Poprawność odpowiadania',
      gradeAverage: 'Średnia ocen',
      timeUsed: 'Wykorzystany czas',
      answerTime: 'Czas odpowiedzi',
      correctAnswers: 'Poprawne odpowiedzi',
      wrongAnswers: 'Błędne odpowiedzi',
      algorithmRating: 'Ocena algorytmu',
      questionType: 'Typ pytania',
      quizzes: '{{ count }} $t(words:quiz, { "count": {{ count }} })',
      occurrences: '{{ count }} $t(words:occurrence, { "count": {{ count }} })',
      ranking: {
        name: 'Imię Nazwisko',
        title: 'Ranking pracowników',
        displayFrom: 'Wyświetl od',
        quizMark: 'Ocena z quizu',
        testMark: 'Ocena z testu manualnego',
        noData: 'Brak danych do wyświetlenia w rankingu',
      }
    },
    statistics: {
      title: 'Statystyka',
      details: {
        averageResponseTime: 'Średni czas odpowiadania',
        standardDeviation: 'Odchylenie standardowe',
        averageCorrectness: 'Średnia poprawność',
        averageIncorrectAnswers: 'Średnia błędnych odpowiedzi',
        averageTimeOfTaskSolution: 'Średni czas potrzebny na rozwiązanie zadania',
        averageTimeInPercentage: 'Średni czas w skali procentowej',
        averageResponseCorrectness: 'Średnia poprawność odpowiedzi',
        averageCorrectnessInPercentage: 'Poprawność odpowiedzi w skali procentowej',
      },
    },
    analysis: {
      title: 'Analiza',
      sortBy: 'Sortuj po',
      trainingId: 'Identyfikator szkolenia',
      stepId: 'Id kroku',
      averageCorrectAnswers: 'Średnia ilość poprawnych odpowiedzi',
      averageUsedTime: 'Średni wykorzystany czas',
    },
    courses: {
      createNew: 'Stwórz nowy kurs',
      empty: 'Brak kursów'
    },
    companies: {
      title: 'Firmy',
      name: 'Nazwa firmy',
      addNew: 'Dodaj nową firmę',
      nip: 'Numer NIP',
      storageSpaceLimit: 'Limit miejsca',
      errors: {
        name: {
          required: 'Nazwa jest wymagana',
        },
        nip: {
          required: 'Numer NIP jest wymagany',
          length: 'Numer NIP powinien składać się z 10 cyfr',
        },
        storageSpaceLimit: {
          required: 'Limit miejsca jest wymagany',
        },
      },
      action: {
        success: {
          add: 'Firma "{{ name }}" została dodana',
          edit: 'Firma "{{ name }}" została zaktualizowana',
          setActive: 'Status firmy został zaktualizowany',
        },
        error: {
          add: 'Wystąpił błąd podczas próby dodania firmy "{{ name }}"',
          edit: 'Wystąpił błąd podczas próby edycji firmy',
          setActive: 'Wystąpił błąd podczas próby zmiany statusu firmy',
        },
      },
    },
    home: {
      title: 'Witaj w aplikacji internetowej Bell VR!',
      nextAppointment: 'Sprawdź szczegóły twojego najbliższego spotkania',
      lastAppointment: 'Sprawdź szczegóły twojego ostatniego spotkania',
      account: 'Zmień dane i ustawienia konta, takie jak nazwa użytkownika lub język',
      teacherApp: 'Aplikacja nauczyciela',
      desktopApp: 'Aplikacja szkoleniowa',
      downloadHelper: 'Jak pobrać aplikację VR?',
      seeInstruction: 'Zobacz instrukcję',
      arrangeAppointment: 'Zorganizuj spotkanie',
      addUser: 'Dodaj użytkownika',
      yourCompany: 'Twoja firma',
      subscriptionType: 'Twój typ subskrypcji',
      yourAppointments: 'Twoje spotkania',
      changeNow: 'Zmień teraz',
      firstSteps: {
        text: 'Pierwsze kroki',
        users: {
          title: 'Załóż konta użytkowników',
          content: 'Wprowadź użytkowników do systemu. Aby działał poprawnie powinien zostać dodany przynajmniej jeden nauczyciel. Każdemu nowo dodanemu użytkownikowi zostaną wysłane wiadomości email: potwierdzenie konta oraz link do formularzu ustawiania hasła do konta.',
        },
        knowledgeBase: {
          title: 'Dodaj materiały do bazy wiedzy',
          content: 'Otrzymane pliki prezentacji lub testów należy wstawić do bazy wiedzy. Dla każdego zestawu plików należy wprowadzić nazwę, opis, przybliżony czas trwania oraz przybliżoną trudność. Jeżeli nie chcesz, żeby dodana zawartość była jeszcze widoczna dla wszystkich ustaw widoczność jako "Autor". Możesz opcjonalnie dodać również zdjęcie. Dodane prezentacje i testy mogą później być grupowane w zakładce "Baza wiedzy"',
        },
        appointment: {
          title: 'Stwórz pierwsze spotkanie',
          content: 'Stwórz swoje pierwsze spotkanie. Musisz podać tytuł, opis, czas spotkania, prowadzącego. Dodaj do spotkania jego uczestników korzystając z sekcji "Uczestnicy". W sekcji "Zawartość" należy umieścić wszystkie prezentacje i testy z bazy wiedzy które będą omawiane podczas spotkania, jeżeli nie chcesz tego robić w tym momencie, możesz to zrobić w dowolnym momencie później podczas edycji spotkania. Możesz również opcjonalnie dodać materiały pomocnicze, które użytkownicy będą mogli pobrać lub wyswietlić aby lepiej przygotować się do spotkania.',
        },
      },
    },
    companyStatistics: {
      members: 'Członkowie',
      courses: 'Kursy',
      groups: 'Grupy',
      teachers: 'Nauczyciele',
      pastAppointments: 'Przeszłe spotkania',
    },
    language: {
      action: {
        success: {
          change: 'Język został zmieniony na $t(language.{{browserCode}})',
        },
        error: {
          change: 'Wystąpił błąd podczas próby zmiany języka',
        },
      },
    },
    notifications: {
      title: 'Ustawienia powiadomień',
      redirectMessage: 'Ustawienia powiadomień',
      labels: {
        email: 'Powiadomienia przesyłane również na adres e-mail',
      },
      menu: {
        title: 'Powiadomienia',
        loadMore: 'Załaduj więcej',
        error: 'Wystąpił błąd podczas próby pobrania powiadomień',
        filter: {
          all: 'Wszystkie',
          notSeen: 'Nieprzeczytane',
        },
        [NotificationType.APPOINTMENT_DATE_CHANGED]: 'Nowy termin spotkania <primary>{{ name }}</primary>: <primary>{{ date }}</primary>',
        [NotificationType.APPOINTMENT_REMOVED]: 'Usunięto spotkanie {{ name }}',
        [NotificationType.APPOINTMENT_SOON]: {
          future: 'Spotkanie <primary>{{ name }}</primary> zaczyna się za <primary>{{ x }}</primary> $t(words:minutes, { "count": {{ count }} })',
          archived: 'Spotkanie <primary>{{ name }}</primary> zaczęło się <primary>{{ date }}</primary>',
        },
        [NotificationType.CONTENT_UPDATED_ON_APPOINTMENT]: 'Zaktualizowano materiały ze spotkania <primary>{{ name }}</primary>',
        [NotificationType.NEW_CONTENT_ON_APPOINTMENT]: 'Dodano nowe materiały do <primary>{{ name }}</primary>',
        [NotificationType.NEW_MSG_ON_APPOINTMENT]: 'Nowa wiadomość w <primary>{{ name }}</primary>',
        [NotificationType.NEW_QUIZ_ON_APPOINTMENT]: 'Masz nowy quiz do uzupełnienia w <primary>{{ name }}</primary>',
        [NotificationType.NEW_REPLY_ON_APPOINTMENT]: 'Nowa odpowiedź w <primary>{{ name }}</primary>',
        [NotificationType.NEW_TEST_ON_APPOINTMENT]: 'Masz nowy test do uzupełnienia w <primary>{{ name }}</primary>',
        [NotificationType.QUIZ_UPDATED_ON_APPOINTMENT]: 'Quiz do <primary>{{ name }}</primary> został zaktualizowany',
        [NotificationType.TEST_UPDATED_ON_APPOINTMENT]: 'Test do <primary>{{ name }}</primary> został zaktualizowany',
        [NotificationType.USER_ADDED_TO_APPOINTMENT]: 'Masz nowe spotkanie <primary>{{ name }}</primary>',
        [NotificationType.USER_REMOVED_FROM_APPOINTMENT]: 'Zostałeś usunięty ze spotkania {{ name }}',
      },
      action: {
        success: 'Ustawienia powiadomień zostały zaktualizowane',
        error: {
          fetch: 'Wystąpił błąd podczas próby zmiany ustawień powiadomień',
          setSeen: 'Wystąpił błąd podczas próby oznaczenia powiadomienia jako przeczytane',
        },
      },
    },
    modals: {
      confirmDeletion: 'Potwierdź usunięcie',
      addMaterials: {
        title: 'Dodaj materiały',
        tabs: {
          pdf: 'PDF',
          url: 'Linki',
        },
        addPdf: 'Dodaj plik pdf',
        addUrl: 'Dodaj url (np. do filmu w serwisie YouTube)',
        emptySelected: 'Brak materiałów dodatkowych do dodania',
        urlPlaceholder: 'Wpisz url...',
        noMaterialDatabase: 'Brak materiałów w bazie',
        name: 'Nazwa widoczna przez uczestników (opcjonalna)',
        namePlaceholder: 'Wprowadź nazwę...',
        url: 'Url',
      },
      addKnowledgeBaseFiles: {
        title: 'Dodaj pliki',
        tabs: {
          database: 'Z bazy danych',
          own: 'Dodaj własne',
        },
        searchPlaceholder: 'Wpisz nazwę aby wyszukać...',
        emptySelected: 'Brak plików do dodania',
        urlPlaceholder: 'Wpisz url...',
        noMaterialDatabase: 'Brak plików w bazie',
        delete: {
          confirmTitle: 'Potwierdź usunięcie pliku',
          confirmSubtext: 'Usunięte mogą zostać jedynie nieużywane pliki. Usunięcie pliku z bazy jest nieodwracalne!',
          error: 'Błąd: Plik może być w użyciu',
        },
        upload: 'Przesyłanie',
      },
      addParticipants: {
        title: 'Znajdź uczestników',
        searchPlaceholder: 'Wpisz imię lub nazwisko aby wyszukać...',
        noUsers: 'Brak użytkowników, których można dodać do spotkania',
      },
      moveElement: {
        title: 'Zmień lokalizację elementu',
        selectValue: 'Wybierz docelową lokalizację...',
        defaultValue: 'Baza wiedzy (główny katalog)'
      },
      content: {
        title: 'Wybierz elementy',
        select: 'Zaznacz wszystkie grupy albo indywidualne elementy poprzez kliknięcie na pola wyboru po prawej stronie',
      },
      presentation: {
        title: 'Wybierz prezentację',
      },
    },
    sort: {
      [SORT_NONE]: '-',
      [ALPHABETICAL]: 'Alfabetycznie',
      [ALPHABETICAL_REVERSE]: 'Alfabetycznie odwrotnie',
      [NEWEST]: 'Od najnowszych',
      [OLDEST]: 'Od najstarszych',
    },
    group: {
      [GroupType.ALL]: 'Wszystkie',
      [GroupType.GROUP]: 'Grupa',
      [GroupType.PRESENTATION]: 'Prezentacja',
      [GroupType.TEST]: 'Test interaktywny',
      [GroupType.QUIZ]: 'Quiz',
      [GroupType.SANDBOX_TEST]: 'Test sandbox',
    },
    questionType: {
      [QuizQuestionType.SINGLE_CHOICE]: 'Jednokrotnego wyboru',
      [QuizQuestionType.MULTIPLE_CHOICE]: 'Wielokrotnego wyboru',
    },
    visibility: {
      [PUBLIC]: 'Publiczne',
      [COMPANY]: 'Firma',
      [AUTHOR]: 'Autor',
    },
    difficulty: {
      1: 'Bardzo łatwy',
      2: 'Łatwy',
      3: 'Średni',
      4: 'Trudny',
      5: 'Bardzo trudny',
    },
    roles: {
      [ADMIN_ROLE]: 'Administrator',
      [COMPANY_ROLE]: 'Konto Firmowe',
      [TEACHER_ROLE]: 'Nauczyciel',
      [PARTICIPANT_ROLE]: 'Uczestnik'
    },
    rankingScopeType: {
      [RankingScopeType.YEAR]: 'Rok',
      [RankingScopeType.MONTH]: 'Miesiąc',
      [RankingScopeType.WEEK]: 'Tydzień'
    },
    analysisScopeType: {
      [AnalysisScopeType.CORRECTNESS]: 'Poprawnych odpowiedziach',
      [AnalysisScopeType.TIME]: 'Wykorzystanym czasie',
    },
    educationLevels: {
      [EducationLevel.HIGHER]: 'Wyższe',
      [EducationLevel.PRIMARY]: 'Podstawowe',
      [EducationLevel.SECONDARY]: 'Średnie',
      [EducationLevel.LOWER_SECONDARY]: 'Gimnazjalne',
      [EducationLevel.VOCATIONAL]: 'Zawodowe',
      [EducationLevel.UNKNOWN]: 'Nieznane',
    },
    apiErrors: {
      [parseErrorCodeToi18nKey(EMAIL_AWAIT_CONFIRMATION)]: 'Konto nie zostało zaktywowane',
      EmailAvailable: 'E-mail jest już zajęty',
    },
    timeZones: {
      pagoPago: '(GMT-11:00) Pago Pago',
      aleutian: '(GMT-10:00) Aleuty',
      alaska: '(GMT-09:00) Alaska',
      pacific: '(GMT-08:00) Czas Pacyficzny',
      mountain: '(GMT-07:00) Czas Górski',
      central: '(GMT-06:00) Czas Centralny',
      eastern: '(GMT-05:00) Czas Wschodni',
      halifax: '(GMT-04:00) Czas Atlantycki',
      buenosAires: '(GMT-03:00) Buenos Aires',
      saoPaulo: '(GMT-02:00) Sao Paulo',
      capeVerde: '(GMT-01:00) Wyspy Zielonego Przylądka',
      london: '(GMT+00:00) Londyn',
      belgrade: '(GMT+01:00) Belgrad',
      jerusalem: '(GMT+02:00) Jerozolima',
      minsk: '(GMT+03:00) Mińsk',
      dubai: '(GMT+04:00) Dubaj',
      kabul: '(GMT+04:30) Kabul',
      uzbekistan: '(GMT+05:00) Uzbekistan',
      india: '(GMT+05:30) Indyjski Czas Standardowy',
      kathmandu: '(GMT+05:45) Katmandu',
      dhaka: '(GMT+06:00) Dhaka',
      myanmar: '(GMT+06:30) Mjanma',
      bangkok: '(GMT+07:00) Bangkok',
      hongkong: '(GMT+08:00) Hong Kong',
      eucla: '(GMT+08:45) Zachodnia Australia (Eucla)',
      tokyo: '(GMT+09:00) Tokyo',
      darwin: '(GMT+09:30) Australijki Czas Centralny - Darwin',
      brisbane: '(GMT+10:00) Australijki Czas Wschodni - Brisbane',
      adelaide: '(GMT+10:30) Czas Środkowoaustralijski Letni - Adelaide',
      vladivostok: '(GMT+11:00) Vladivostok',
      newZealand: '(GMT+12:00) Nowa Zeladnia',
      tonga: '(GMT+13:00) Tonga',
      kiritimati: '(GMT+14:00) Kiritimati',
    },
  },
}
