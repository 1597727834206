import { useMemo, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress, Typography } from '@material-ui/core'
import { useHistory, useLocation, useParams } from 'react-router'
import { Check, Close } from '@material-ui/icons'

import { useGetKnowledgeBaseEntityQuery } from 'api/queries/knowledgeBase/knowledgeBase'
import { RestrictedContent } from 'components/auth/RestrictedContent/RestrictedContent'
import { DifficultySlider } from 'components/data/DifficultySlider/DifficultySlider'
import { QuestionList } from 'components/data/QuestionList/QuestionList'
import { ADMIN_ROLE, COMPANY_ROLE, TEACHER_ROLE } from 'constants/roleGroups'
import { PRESENTATION, QUIZ, TEST } from 'constants/knowledgeBaseContent'
import { useDestination } from 'hooks/useDestination'
import { useIsMobile } from 'hooks/useIsMobile'
import { useCompanyId } from 'providers/CompanyProvider'
import * as Styled from '../knowledgeBase.styles'

interface ParamTypes {
  id: string
}

const KnowledgeBaseContentScreen = (): JSX.Element => {
  const { t } = useTranslation()
  const { id } = useParams<ParamTypes>()
  const isMobile = useIsMobile()
  const history = useHistory()
  const { pathname } = useLocation()
  const companyId = useCompanyId() || 0
  const didMount = useRef(false)

  const {
    data: knowledgeBaseData,
    isLoading,
    isError,
  } = useGetKnowledgeBaseEntityQuery(+id)

  const destination = useDestination(knowledgeBaseData)

  const handleEdit = () => {
    history.push(`${pathname}/edit`)
  }

  useEffect(() => {
    if (didMount.current) {
      history.replace('/knowledge-base')
    } else {
      didMount.current = true
    }
  }, [companyId, history])

  const imageLink = useMemo(() => (
    knowledgeBaseData?.image?.miniaturePath
  ), [knowledgeBaseData])

  return (
    <Styled.RootContainer>
      <Styled.HeaderContainer>
        <Typography variant='h2'>
          {t('knowledgeBase.content')}
        </Typography>
        <RestrictedContent accessRoles={[ADMIN_ROLE]}>
          <Styled.EditButton $isMobile={isMobile} onClick={handleEdit}>
            {t('common.edit')}
          </Styled.EditButton>
        </RestrictedContent>
      </Styled.HeaderContainer>
      {isError || isLoading ? (
        <Styled.HelpersContainer>
          {isError && <Typography color='error'>{t('common.errors.generic')}</Typography>}
          {isLoading && <CircularProgress color='primary' />}
        </Styled.HelpersContainer>
      ) : (
        <Styled.ContentContainer>
          <Styled.InfoSection
            label={t('knowledgeBase.destination')}
            value={destination}
          />
          <RestrictedContent accessRoles={[TEACHER_ROLE, COMPANY_ROLE, ADMIN_ROLE]}>
            <Styled.InfoSection
              label={t('common.type')}
              value={t(`group.${[knowledgeBaseData?.dataType]}`)}
            />
          </RestrictedContent>
          <RestrictedContent accessRoles={[TEACHER_ROLE, COMPANY_ROLE, ADMIN_ROLE]}>
            <Styled.InfoSection
              label={t('common.visibility')}
              value={t(`visibility.${[knowledgeBaseData?.visibility]}`)}
            />
          </RestrictedContent>
          <Styled.InfoSection
            label={t('common.name')}
            value={knowledgeBaseData?.name}
          />
          <Styled.InfoSection
            label={t('common.description')}
            value={knowledgeBaseData?.description}
          />
          <RestrictedContent accessRoles={[TEACHER_ROLE, COMPANY_ROLE, ADMIN_ROLE]}>
            <Styled.InfoSection
              label={t('knowledgeBase.photo')}
              value={!imageLink ? t('knowledgeBase.emptyPhoto') : ''}
            >
              {imageLink && (
                <Styled.ImagePreview imageSrc={imageLink} />
              )}
            </Styled.InfoSection>
            <Styled.InfoSection
              label={t('knowledgeBase.difficulty')}
              value={
                <Styled.DifficultySliderContainer>
                  <DifficultySlider
                    name='difficulty'
                    value={knowledgeBaseData?.difficulty || 3}
                    track={false}
                    disabled
                  />
                </Styled.DifficultySliderContainer>
              }
            />
            <Styled.InfoSection
              label={t('knowledgeBase.duration')}
              value={`${(knowledgeBaseData?.duration || 0) / 60} ${t('common.minutes')}`}
            />
            {(knowledgeBaseData?.dataType === PRESENTATION
              || knowledgeBaseData?.dataType === TEST) && (
              <>
                <Styled.InfoSection
                  label={t('knowledgeBase.scene')}
                  value={knowledgeBaseData?.scene?.fileName || t('common.notAssigned')}
                />
                <Styled.InfoSection
                  label={t('knowledgeBase.timeline')}
                  value={knowledgeBaseData?.timeline?.fileName || t('common.notAssigned')}
                />
              </>
            )}
            {knowledgeBaseData?.dataType === QUIZ && (
              <>
                <Styled.CheckboxSection>
                  <Styled.Checkbox
                    disabled
                    checked={knowledgeBaseData.canGoBack}
                    label={t('knowledgeBase.canGoBack')}
                    icon={<Close color='disabled' />}
                    checkedIcon={<Check color='primary' />}
                    labelFontSize='18px'
                    labelFontWeight='bold'
                  />
                  <Styled.Checkbox
                    disabled
                    checked={knowledgeBaseData.showAnswers}
                    label={t('knowledgeBase.showAnswers')}
                    icon={<Close color='disabled' />}
                    checkedIcon={<Check color='primary' />}
                    labelFontSize='18px'
                    labelFontWeight='bold'
                  />
                  <Styled.Checkbox
                    disabled
                    checked={knowledgeBaseData.randomQuestionOrder}
                    label={t('knowledgeBase.randomQuestionOrder')}
                    icon={<Close color='disabled' />}
                    checkedIcon={<Check color='primary' />}
                    labelFontSize='18px'
                    labelFontWeight='bold'
                  />
                </Styled.CheckboxSection>
                <RestrictedContent
                  accessRoles={[TEACHER_ROLE, COMPANY_ROLE, ADMIN_ROLE]}
                >
                  <Styled.InfoSection
                    label={t('knowledgeBase.questionPool')}
                    value={
                      knowledgeBaseData.questions?.length
                        ? ''
                        : t('knowledgeBase.noQuestions')
                    }
                    editValueComponent={knowledgeBaseData.questions?.length && (
                      <QuestionList
                        questions={knowledgeBaseData.questions}
                        isDisplayOnly
                      />
                    )}
                  />
                </RestrictedContent>
              </>
            )}
          </RestrictedContent>
        </Styled.ContentContainer>
      )}
    </Styled.RootContainer>
  )
}

export { KnowledgeBaseContentScreen }
