import React from 'react'
import { useHistory } from 'react-router-dom'
import { CircularProgress, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { RestrictedContent } from 'components/auth/RestrictedContent/RestrictedContent'
import { ADMIN_ROLE } from 'constants/roleGroups'
import { Card, CardDataType } from 'components/data/Card/Card'
import { ContentCenter } from 'components/layout/ContentCenter/ContentCenter'
import * as Styled from './CardsList.styles'

interface CardsListProps {
  isLoading?: boolean
  data: CardsListItemType[]
  emptyListInfoText: string
  onMove?: (id: number) => void
  onEdit?: (id: number, name: string, dataType?: string) => void
  onDelete?: (id: number) => void
  onCopy?: (id: number) => void
}

interface CardsListItemType {
  id: number,
  name: string,
  creationDateTime: string,
  dataType?: CardDataType,
  image?: string,
}

const CardsList: React.FC<CardsListProps> = ({
  isLoading = false,
  data,
  emptyListInfoText,
  onMove: handleMove,
  onEdit: handleEdit,
  onDelete: handleDelete,
  onCopy: handleCopy,
}) => {
  const history = useHistory()
  const { t } = useTranslation()

  if (isLoading) {
    return (
      <ContentCenter>
        <CircularProgress />
      </ContentCenter>
    )
  }

  const getItemPath = (id: number, dataType?: string) => (
    `${history.location.pathname}/${dataType?.toLowerCase()}/${id}`
  )

  return (
    <>
      {data.length ? (
        <Styled.Grid>
          {data.map(({
            id,
            name,
            creationDateTime,
            dataType,
            image,
          }) => (
            <Card
              key={id}
              title={name}
              image={image}
              creationDateTime={creationDateTime}
              dataType={dataType}
              onClick={() => history.push(getItemPath(id, dataType))}
              onEdit={() => handleEdit?.(id, name, dataType)}
              onMove={() => handleMove?.(id)}
              onDelete={() => handleDelete?.(id)}
              onCopy={() => handleCopy?.(id)}
            />
          ))}
        </Styled.Grid>
      ) : (
        <Styled.NoItemsContainer item>
          <Typography variant='h5' color='primary'>
            {emptyListInfoText}
          </Typography>
          <RestrictedContent accessRoles={[ADMIN_ROLE]}>
            <Typography variant='subtitle1' color='textSecondary'>
              {t('common.emptySubtext')}
            </Typography>
          </RestrictedContent>
        </Styled.NoItemsContainer>
      )}
    </>
  )
}

export type { CardsListItemType }
export { CardsList }
