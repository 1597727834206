import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import axios from 'axios'

import { REFRESH_TOKEN_QUERY_KEY } from 'api/queriesKeys'
import { RefreshResponse } from './types'

const useRefreshTokenQuery = (options?: Omit<UseQueryOptions<RefreshResponse, Error>, 'queryKey'>)
: UseQueryResult<RefreshResponse, Error> => (
  useQuery(
    REFRESH_TOKEN_QUERY_KEY,
    () => axios
      .get('/api/token/refresh', {
        withCredentials: true
      })
      .then((response) => response.data),
    options
  )
)

export { useRefreshTokenQuery }
