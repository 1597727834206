import styled from '@emotion/styled'
import {
  Grid,
  CircularProgress as MuiCircularProgress,
} from '@material-ui/core'

import { Button } from 'components/inputs/Button/Button'

const StyledGrid = styled(Grid)`
  padding: ${({ theme }) => theme.spacing(3)}px;
`

const StyledButton = styled(Button)`
  margin: ${({ theme }) => theme.spacing(1, 2, 1, 0)};
  width: 215px;
  font-size: 15px;
  white-space: nowrap;
`

const CardsListGrid = styled(Grid)`
  padding: ${({ theme }) => theme.spacing(3, 0)};
`

const CircularProgress = styled(MuiCircularProgress)`
  margin: ${({ theme }) => theme.spacing(2, 'auto')};
`

const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
`

export {
  StyledGrid as Grid,
  StyledButton as Button,
  CardsListGrid,
  CircularProgress,
  LoadMoreContainer,
}
