export const REFRESH_TOKEN_QUERY_KEY = 'refreshToken'
export const USER_DETAILS_QUERY_KEY = 'userDetails'
export const GET_QUIZ_QUESTION_QUERY_KEY = 'quizQuestion'
export const KNOWLEDGE_BASE_ENTITY_QUERY_KEY = 'knowledgeBase'
export const NOTIFICATIONS_QUERY_KEY = 'notifications'
export const GET_RANKING_QUERY_KEY = 'ranking'
export const FORUM_ENTRIES_QUERY_KEY = 'forumEntry'
export const GET_ANALYSIS_QUERY_KEY = 'analysis'
export const STATISTICS_QUERY_KEY = 'statistics'
export const STATISTICS_ADMIN_QUERY_KEY = 'statisticsAdmin'
export const QUIZ_ANALYSIS_QUERY_KEY = 'quizAnalysis'
export const USER_KNOWLEDGE_BASE_PERMISSIONS = 'userKnowledgeBasePermissions'
export const OWN_COMPANY_USERS_QUERY_KEY = 'ownCompanyUsers'
export const COMPANY_USERS_QUERY_KEY = 'companyUsers'
export const OWN_COMPANY_TEACHERS_QUERY_KEY = 'ownCompanyTeachers'
export const COMPANY_TEACHERS_QUERY_KEY = 'companyTeachers'
