import { useTranslation } from 'react-i18next'
import difference from 'lodash/difference'

import { GroupType } from 'types/GroupType'
import { Select, SelectProps } from 'components/inputs/Select/Select'
import * as Styled from './GroupSelect.styles'

interface GroupSelectProps extends SelectProps {
  className?: string,
  without?: GroupType[]
  label?: string
}

const GroupSelect: React.FC<GroupSelectProps> = ({
  className,
  without = [],
  label,
  ...rest
}) => {
  const { t } = useTranslation()

  const groupTypes = difference(Object.values(GroupType), without)

  return (
    <Select
      label={label ?? t('common.type')}
      className={className}
      {...rest}
    >
      {groupTypes.map((type) => (
        <Styled.MenuItem key={type} value={type}>
          {t(`group.${[type]}`)}
        </Styled.MenuItem>
      ))}
    </Select>
  )
}

export { GroupSelect }
