import { useHistory } from 'react-router-dom'

import { useAddKnowledgeBaseIdObjectMutation } from 'api/mutations/knowledgeBase/knowledgeBase'
import { useLocationReturnFunction } from './useLocationReturnFunction'

const useCreateKnowledgeBaseContent = () => {
  const history = useHistory()
  const handleReturn = useLocationReturnFunction()

  const onSuccess = handleReturn || (() => history.goBack())

  const idMutation = useAddKnowledgeBaseIdObjectMutation({
    onSuccess,
  })

  return idMutation
}

export { useCreateKnowledgeBaseContent }
